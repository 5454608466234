<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.638"
    height="34.638"
    viewBox="0 0 34.638 34.638"
    alt="Unity Logo"
  >
    <g id="Group_241" data-name="Group 241" transform="translate(14 -111.23)">
      <g id="Group_240" data-name="Group 240" transform="translate(-14 111.23)">
        <path
          id="Path_63"
          data-name="Path 63"
          d="M326.46,257.73a22.8,22.8,0,0,1,11.43-4.913,17.265,17.265,0,0,0-22.842,0A22.868,22.868,0,0,1,326.46,257.73Z"
          transform="translate(-309.159 -248.5)"
          fill="#387f9b"
        />
        <path
          id="Path_64"
          data-name="Path 64"
          d="M324.108,259.887a21.111,21.111,0,0,0-11.662-4.485,17.281,17.281,0,0,0-4.284,9.993,19.611,19.611,0,0,1,9.974,3.744A23.13,23.13,0,0,1,324.108,259.887Z"
          transform="translate(-308.096 -249.559)"
          fill="#387f9b"
          opacity="0.65"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M330.068,259.895a21.1,21.1,0,0,1,11.7-4.5,17.214,17.214,0,0,1,4.259,9.976,19.631,19.631,0,0,0-9.99,3.76A22.878,22.878,0,0,0,330.068,259.895Z"
          transform="translate(-311.478 -249.558)"
          fill="#387f9b"
          opacity="0.65"
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="M327.464,261.981a21.266,21.266,0,0,1,5.88,9.269,19.838,19.838,0,0,0-5.859,9.179,19.758,19.758,0,0,0-5.886-9.169A21.323,21.323,0,0,1,327.464,261.981Z"
          transform="translate(-310.17 -250.568)"
          fill="#387f9b"
          opacity="0.35"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M317.6,272.973a17.968,17.968,0,0,0-9.513-3.771,17.355,17.355,0,0,0,9.5,15.274,23.168,23.168,0,0,1-.724-5.727A22.931,22.931,0,0,1,317.6,272.973Z"
          transform="translate(-308.084 -251.675)"
          fill="#387f9b"
          opacity="0.35"
        />
        <path
          id="Path_68"
          data-name="Path 68"
          d="M347.3,269.173a17.339,17.339,0,0,1-9.563,15.319,22.906,22.906,0,0,0,.007-11.524A18,18,0,0,1,347.3,269.173Z"
          transform="translate(-312.661 -251.671)"
          fill="#387f9b"
          opacity="0.35"
        />
        <g
          id="Group_239"
          data-name="Group 239"
          transform="translate(10.449 22.523)"
          opacity="0.2"
        >
          <path
            id="Path_69"
            data-name="Path 69"
            d="M320.92,275.1a18.047,18.047,0,0,1,5.495,12.113,17.4,17.4,0,0,1-4.932-.984,21.5,21.5,0,0,1-.563-11.13Z"
            transform="translate(-320.441 -275.103)"
            fill="#387f9b"
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M335.078,275.117a21.506,21.506,0,0,1,.474,4.539,21.286,21.286,0,0,1-1.044,6.6,17.238,17.238,0,0,1-4.909.961A18.147,18.147,0,0,1,335.078,275.117Z"
            transform="translate(-321.855 -275.105)"
            fill="#387f9b"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
